import React from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { paidStatus } from 'payments/propTypes';
import { isEmpty } from 'lodash';
import { useCompanyInfo } from 'common/hooks';

import InvoiceReport from '../InvoiceReport';
import { getInvoiceContainer, getCountriesList } from '../../reducers/invoices';

const ExportInvoiceButton = ({ full, status }) => {
  const { id } = useParams();
  const invoice = useSelector((state) => getInvoiceContainer(state, id));
  const countries = useSelector((state) => getCountriesList(state))?.countries;
  const { businessInfo } = useCompanyInfo({
    withCountries: true,
  });

  if (isEmpty(invoice) || isEmpty(businessInfo) || isEmpty(countries))
    return null;

  const documentType = status === paidStatus ? 'receipt' : 'invoice';

  const fileName = `${documentType}.pdf`;

  return (
    <PDFDownloadLink
      document={
        <InvoiceReport
          invoice={invoice}
          businessInfo={businessInfo}
          buyerCountries={countries}
        />
      }
      fileName={fileName}
    >
      {({ loading }) => (
        <Button
          full={full}
          kind="primary"
          disabled={loading}
          icon={
            <Icon.Arrow
              description={`Arrow small down`}
              size="medium"
              direction="down"
            />
          }
        >
          <button type="button">
            {loading ? 'Generating PDF...' : `Download ${documentType}`}
          </button>
        </Button>
      )}
    </PDFDownloadLink>
  );
};

ExportInvoiceButton.defaultProps = {
  full: PropTypes.false,
  status: '',
};

ExportInvoiceButton.propTypes = {
  full: PropTypes.bool,
  status: PropTypes.string,
};

export default ExportInvoiceButton;
