import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import { selectors as featuresSelectors } from 'features';
import { route as routePropType } from 'common/propTypes';
import NotFound from 'common/components/NotFound';
import PrivateRoute from 'app/containers/PrivateRoute';
import PublicRoute from 'app/containers/PublicRoute';
import SignUpPage from './SignUp';
import SetPasswordPage from './SetPassword';
import GetStartedPage from './GetStarted';

// TODO: Remove isSSOActive once SSO is fully rolled out
const Routes = ({
  isMerchantRegistrationActive,
  match,
  sandboxMode,
  isSSOActive,
}) => (
  <Switch>
    <PrivateRoute
      path={`${match.path}/get-started`}
      component={GetStartedPage}
      exact
    />
    {isMerchantRegistrationActive && !isSSOActive && (
      <PublicRoute
        path={`${match.path}/sign-up`}
        component={SignUpPage}
        sandboxMode={sandboxMode}
        exact
      />
    )}
    {!isSSOActive && (
      <PublicRoute
        path={`${match.path}/set_password`}
        component={SetPasswordPage}
        exact
      />
    )}
    <PublicRoute component={NotFound} />
  </Switch>
);

Routes.propTypes = {
  ...routePropType,
  isMerchantRegistrationActive: PropTypes.bool.isRequired,
  sandboxMode: PropTypes.bool,
  isSSOActive: PropTypes.bool,
};
export default connect((state) => ({
  ...featuresSelectors.getOnboardingFlags(state),
  isSSOActive: featuresSelectors.isSSOActive(state),
}))(Routes);
