import React, { Component } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, includes } from 'lodash';

import Icon from '../../Icon';
import { icon as iconPropType } from '../../../propTypes';
import colors from '../../../styles/colors';
import { track } from '../../../analytics';

import styles from './index.module.css';

const iconsMap = {
  cog: Icon.Gear,
  comment2question: Icon.Comment2Question,
  payments: Icon.ShoppingCart,
  play: Icon.Play,
  plug: Icon.CodeRec,
  power: Icon.Power,
  shield_check: Icon.ShieldCheck,
  settlements: Icon.Invoice2,
};

const LinkIcon = ({ icon, fill, small }) => {
  const SidebarIcon = iconsMap[icon.name];
  return (
    <div
      className={classNames(styles.iconWrapper, {
        [styles['iconWrapper--small']]: small,
      })}
    >
      <SidebarIcon description={icon.description} fill={fill} />
    </div>
  );
};

LinkIcon.propTypes = {
  icon: propTypes.shape({
    name: propTypes.string,
    description: propTypes.string,
  }).isRequired,
  fill: propTypes.string.isRequired,
};

class SidebarLink extends Component {
  state = {
    isHovered: false,
  };

  toggleHover = () => {
    this.setState((state) => ({
      isHovered: !state.isHovered,
    }));
  };

  linkEvents = (isActive, callback) => () => {
    const { name } = this.props;
    track(`Clicked ${name} on Sidebar`);
    return isActive
      ? (evt) => {
          evt.preventDefault();
        }
      : callback;
  };

  iconColor = (isActive, isHovered) => {
    if (isActive || isHovered) return 'white';
    return colors['rebranding-md-color-brand-purple-light'];
  };

  pathPattern(link) {
    const { pathPattern } = this.props;

    return new RegExp(pathPattern || link);
  }

  isActive(link) {
    const { activePath } = this.props;
    if (isEmpty(activePath)) return false;

    const pathRegex = this.pathPattern(link);
    if (link === '/') {
      return activePath === link || includes(activePath, '/orders');
    }

    return pathRegex.test(activePath);
  }

  renderLink() {
    const {
      to,
      name,
      icon,
      onClick,
      onKeyPress,
      external,
      targetBlank,
      small,
      style,
    } = this.props;
    const isActive = this.isActive(to);
    const linkClassName = classNames(styles.root, {
      [styles['root--small']]: small,
      [styles.active]: isActive,
    });
    const fill = this.iconColor(isActive, this.state.isHovered);

    const commonProps = {
      onMouseEnter: this.toggleHover,
      onMouseLeave: this.toggleHover,
      onFocus: this.toggleHover,
      onBlur: this.toggleHover,
      className: linkClassName,
      onClick: this.linkEvents(isActive, onClick),
      onKeyPress: this.linkEvents(isActive, onKeyPress),
      'data-test': `${to.substr(1)}-sidebarLink`,
      style,
    };

    const content = (
      <>
        <span>
          {icon && <LinkIcon icon={icon} fill={fill} small={small} />}
        </span>
        <span
          className={classNames(styles.label, {
            [styles['label--small']]: small,
          })}
        >
          {name}
        </span>
      </>
    );

    const externalLinkProps = targetBlank
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};

    if (external) {
      return (
        <a href={to} {...externalLinkProps} {...commonProps}>
          {content}
        </a>
      );
    }

    return (
      <Link to={to} {...commonProps}>
        {content}
      </Link>
    );
  }

  render() {
    return this.renderLink();
  }
}

SidebarLink.propTypes = {
  activePath: propTypes.string,
  to: propTypes.string.isRequired,
  icon: propTypes.shape(iconPropType).isRequired,
  name: propTypes.string.isRequired,
  onClick: propTypes.func,
  onKeyPress: propTypes.func,
  pathPattern: propTypes.string,
  external: propTypes.bool,
  targetBlank: propTypes.bool,
  small: propTypes.bool,
  style: propTypes.shape({}),
};

SidebarLink.defaultProps = {
  activePath: '',
  onKeyPress: () => {},
  onClick: () => {},
  external: false,
  targetBlank: false,
  small: false,
  style: {},
};

export default SidebarLink;
