import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  emailConfirmedIntegration,
  kybBaseInfoSubmitted,
  signedUp,
} from 'common/constants';
import { getStartedRoute, ordersRoute } from 'app/containers/routes';
import { selectors as featuresSelectors } from 'features';

import { selectors as sessionSelectors } from '../../session';

const shouldRedirectToOnboarding = (integrationStatus, complianceStatus) =>
  complianceStatus === kybBaseInfoSubmitted ||
  [signedUp, emailConfirmedIntegration].includes(integrationStatus);

const RouteSelection = ({
  storeIntegrationStatus,
  storeComplianceStatus,
  isSSOActive,
}) => {
  const pathname =
    shouldRedirectToOnboarding(storeIntegrationStatus, storeComplianceStatus) &&
    !isSSOActive
      ? getStartedRoute
      : ordersRoute;

  return <Redirect to={{ pathname }} />;
};

RouteSelection.propTypes = {
  storeIntegrationStatus: PropTypes.string,
  isSSOActive: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  storeIntegrationStatus:
    sessionSelectors.activeOrganization(state)?.integrationStatus,
  storeComplianceStatus:
    sessionSelectors.activeOrganization(state)?.complianceStatus,
  isSSOActive: featuresSelectors.isSSOActive(state),
}))(memo(RouteSelection));
