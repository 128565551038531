import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { motion } from 'framer-motion';

import Icon from 'common/components/Icon';
import colors from '../../styles/colors';
import SidebarLink from './SidebarLink';

import styles from './index.module.css';

const variants = {
  open: { x: 0 },
  closed: { x: '200%' },
};

const transition = {
  type: 'tween',
};

const SignOutLink = (props) => {
  const { href, handleKeyPress } = props;

  return (
    <div>
      <SidebarLink
        icon={{ name: 'power', description: 'Sign out icon' }}
        onKeyPress={handleKeyPress}
        name="Log Out"
        to={href}
        small
      />
    </div>
  );
};

const SupportLink = (props) => {
  const { href, handleKeyPress } = props;

  return (
    <div>
      <SidebarLink
        targetBlank
        style={{ paddingBottom: 0 }}
        icon={{ name: 'comment2question', description: 'Support icon' }}
        onKeyPress={handleKeyPress}
        name="Support"
        to={href}
        external
        small
      />
    </div>
  );
};

SignOutLink.propTypes = {
  href: PropTypes.string.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
};

const List = (props) => {
  const { children, activePath, handleKeyPress } = props;
  const nonEmptyChildren = _.reject(children, (child) => !child);

  return Children.map(nonEmptyChildren, (child) => (
    <li role="menuitem" key={child.props.href}>
      <SidebarLink
        {...child.props}
        activePath={activePath}
        onKeyPress={handleKeyPress}
      />
    </li>
  ));
};

class Sidebar extends Component {
  state = {
    mobileMenu: false,
  };

  handleKeyPress = (evt) => {
    evt.stopPropagation();
    const { onLinkPress } = this.props;

    if (this.acceptableEvent(evt)) onLinkPress();
  };

  handleLogoClick = () => {
    const { resetTable } = this.props;

    resetTable();
  };

  toggleIntercomIcon = () => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: !this.state.mobileMenu,
      });
    }
  };

  handleNavMenuClick = () => {
    this.setState(
      (state) => ({ mobileMenu: !state.mobileMenu }),
      this.toggleIntercomIcon()
    );
  };

  acceptableEvent(evt) {
    // 13 = Enter, 32 = Spacebar
    return evt.which === 13 || evt.which === 32;
  }

  render() {
    const {
      signOutLink: { href },
      supportLink: { href: supportHref },
      children,
      activePath,
    } = this.props;

    return (
      <>
        <div className={styles.root}>
          <div className={styles.logoWrapper}>
            <Link to="/orders" onClick={this.handleLogoClick}>
              <Icon.SymbolxMoney
                fill={colors['rebranding-color-white']}
                size="auto"
              />
            </Link>
          </div>
          <nav
            role="presentation"
            tabIndex={-1}
            aria-label="Main navigation"
            className={styles.nav}
          >
            <ul role="menubar">
              <List
                activePath={activePath}
                handleKeyPress={this.handleKeyPress}
              >
                {children}
              </List>
            </ul>
          </nav>
          <span className={styles.signOutWrapper}>
            <SupportLink
              href={supportHref}
              handleKeyPress={this.handleKeyPress}
            />
            <SignOutLink href={href} handleKeyPress={this.handleKeyPress} />
          </span>
          <span
            className={styles.navButton}
            onClick={this.handleNavMenuClick}
            onKeyDown={this.handleKeyPress}
            role="button"
            tabIndex={0}
          >
            {this.state.mobileMenu ? (
              <Icon.CrossThin size="auto" fill="white" />
            ) : (
              <Icon.NavMenu size="auto" />
            )}
          </span>
        </div>
        <motion.nav
          animate={this.state.mobileMenu ? 'open' : 'closed'}
          initial={{ x: '200%' }}
          className={styles.mobileMenu}
          transition={transition}
          variants={variants}
        >
          <div>
            {this.state.mobileMenu && (
              <List
                activePath={activePath}
                handleKeyPress={this.handleKeyPress}
              >
                {children}
              </List>
            )}
          </div>
          <div>
            <SupportLink
              href={supportHref}
              handleKeyPress={this.handleKeyPress}
            />
            <SignOutLink href={href} handleKeyPress={this.handleKeyPress} />
          </div>
        </motion.nav>
      </>
    );
  }
}

Sidebar.propTypes = {
  activePath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onLinkPress: PropTypes.func,
  resetTable: PropTypes.func.isRequired,
  signOutLink: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
  supportLink: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
};

Sidebar.defaultProps = {
  onLinkPress: () => {},
};

export { SidebarLink };

export default Sidebar;
