import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';

import styles from './index.module.css';

const EnvironmentBanner = ({ env }) => {
  const [topPosition, setTopPosition] = useState(false);

  useEffect(() => {
    const reposition = () => {
      setTopPosition(!document.getElementById('layout'));
    };

    reposition();
    const handlePosition = debounce(reposition, 150);

    window.addEventListener('resize', handlePosition);
    return () => window.removeEventListener('resize', handlePosition);
  });

  return (
    <div
      className={classNames(styles.root, styles[env], {
        [styles.top]: topPosition,
      })}
    >
      <p className={styles.content}>
        You are using the <strong className={styles.highlight}>{env}</strong>{' '}
        environment. It uses test networks (more information{' '}
        <a
          href="https://docs.crypto.xmoney.com/#section/Basics/2.-Try-our-flow"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        ). <strong className={styles.highlight}>Do not use real money!</strong>
      </p>
    </div>
  );
};

EnvironmentBanner.propTypes = {
  env: PropTypes.string.isRequired,
};

export default EnvironmentBanner;
