import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { useOnMountDataRequest, useContract } from 'common/hooks';

import {
  complianceStatuses,
  kybBaseInfoSubmitted,
  kybStarted,
  kybT1Approved,
} from 'common/constants';
import BusinessDetails from 'onboarding/containers/BusinessDetails';
import KybLayout from 'onboarding/components/KybLayout';
import Layout from 'common/containers/Layout';
import LoadingPage from 'common/components/LoadingPage';
import OnboardingCompleteModal from 'payments/components/OnboardingCompleteModal';
import KybMissingSettlements from '../components/KybMissingSettlements';
import PayoutInfo from '../components/PayoutInfo';
import Settlements from '../components/Settlements';
import SettlementsAlerts from '../components/SettlementsAlerts';

import {
  settlements as settlementsSelector,
  isFetchingSettlements as isFetchingSettlementsSelector,
  totals as totalsSelector,
} from '../reducers';
import actions from '../actions';

const useSettlementsData = () => {
  const { isMounting } = useOnMountDataRequest(actions.settlementsRequest);
  const isFetchingSettlements = useSelector(isFetchingSettlementsSelector);
  const settlements = useSelector(settlementsSelector);
  const totals = useSelector(totalsSelector);

  return {
    isFetching: isMounting || isFetchingSettlements,
    settlements,
    totals,
  };
};

const Loading = () => (
  <Layout heading="Settlements">
    <LoadingPage />
  </Layout>
);

const SettlementsContainer = ({
  bankInfo,
  integrationStatus,
  complianceStatus,
  suspendedSettlements,
  isSandboxMode,
  setIntegrationStatus,
  setComplianceStatus,
  isSSOActive,
}) => {
  const { settlements, isFetching } = useSettlementsData();
  const [contract, isLoadingContract] = useContract();

  const [showModal, setShowModal] = useState(false);
  const [showKyb, setShowKyb] = useState(false);

  const updateStatus = (status) => {
    if (complianceStatuses.includes(status)) {
      setShowKyb(false);
      setShowModal(true);
      setComplianceStatus(status);
    }
    setIntegrationStatus(status);
  };
  const displayKyb = () => setShowKyb(true);
  const closeModal = () => setShowModal(false);

  if (isFetching || isLoadingContract) return <Loading />;

  const isKybMissing =
    [kybStarted, kybBaseInfoSubmitted].includes(complianceStatus) &&
    (isSandboxMode || isEmpty(settlements));

  if (showKyb && !isSSOActive)
    return (
      <Layout heading={'Verify your business'}>
        <KybLayout
          integrationStatus={integrationStatus}
          complianceStatus={complianceStatus}
        >
          <BusinessDetails
            integrationStatus={integrationStatus}
            updateStatus={updateStatus}
          />
        </KybLayout>
      </Layout>
    );

  const incompletedOnboarding =
    complianceStatus && complianceStatus !== kybT1Approved;
  const showAlert = incompletedOnboarding || suspendedSettlements;

  const ongoing = Object.values(settlements).filter(
    (el) => el.status === 'accumulating'
  )[0];

  const payoutInfo = (
    <PayoutInfo contract={contract} ongoingSettlement={ongoing} />
  );

  return (
    <Layout heading={'Settlements'} sideContent={!isKybMissing && payoutInfo}>
      {isKybMissing ? (
        <KybMissingSettlements
          isSandboxMode={isSandboxMode}
          handleStartKyb={displayKyb}
        />
      ) : (
        <>
          <OnboardingCompleteModal
            isOpen={showModal}
            onClose={closeModal}
            content={'docsSubmitted'}
          />
          {showAlert && !showModal && (
            <SettlementsAlerts
              complianceStatus={complianceStatus}
              suspendedSettlements={suspendedSettlements}
              displayKyb={displayKyb}
            />
          )}
          <Settlements
            bankInfo={bankInfo}
            settlements={settlements}
            ongoing={ongoing}
          />
        </>
      )}
    </Layout>
  );
};

SettlementsContainer.propTypes = {
  bankInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  integrationStatus: PropTypes.string,
  complianceStatus: PropTypes.string,
  suspendedSettlements: PropTypes.bool.isRequired,
  isSandboxMode: PropTypes.bool.isRequired,
  setIntegrationStatus: PropTypes.func.isRequired,
  setComplianceStatus: PropTypes.func.isRequired,
  isSSOActive: PropTypes.bool.isRequired,
};

export default SettlementsContainer;
