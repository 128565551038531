import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const Checkbox = ({
  id,
  name,
  label,
  onChange,
  value,
  disabled,
  invalid,
  indeterminate,
  ...rest
}) => {
  const rootClasses = classNames(styles.root, {
    [styles.disabled]: disabled,
  });

  /* eslint-disable no-param-reassign */
  const setIndeterminate = (checkbox) => {
    if (checkbox) {
      checkbox.indeterminate = indeterminate;
    }
  };
  /* eslint-enable no-param-reassign */

  return (
    <label
      className={rootClasses}
      htmlFor={name}
      data-test={`checkbox-${name}`}
    >
      <input
        {...rest}
        id={id}
        name={name}
        type="checkbox"
        className={styles.hidden}
        checked={value}
        onChange={onChange}
        aria-invalid={invalid}
        aria-describedby={`${id}-error-message`}
        ref={setIndeterminate}
        disabled={disabled}
      />
      <span className={styles.checkbox} />
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  indeterminate: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  invalid: false,
  indeterminate: false,
  onChange: () => {},
};

export default Checkbox;
