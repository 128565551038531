import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const ErrorMessage = ({ errorId, errorText, id }) => (
  <div
    role="alert"
    className={styles.errorMessage}
    data-error={errorId}
    id={id}
  >
    {errorText}
  </div>
);

ErrorMessage.propTypes = {
  errorId: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ErrorMessage;
