import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';

import { selectors as authSelectors } from 'auth';
import { api } from 'app/services';
import { invoicesRoute } from 'app/containers/routes';
import { route as routePropTypes } from 'common/propTypes';
import { getCountryLabel } from 'common/countryHelpers';
import BackButton from 'common/components/BackButton';
import LoadingPage from 'common/components/LoadingPage';
import NotFound from 'common/components/NotFound';
import Layout from 'common/containers/Layout';
import InvoiceActions from '../components/InvoiceActions';
import InvoiceSentModal from '../components/InvoiceSentModal';
import CancelInvoiceConfirmationModal from '../components/CancelInvoiceConfirmationModal';
import { managePermission, order as orderPropType } from '../propTypes';
import actions from '../actions';
import {
  getCountriesList,
  getInvoiceContainer,
  getLoadInvoicesError,
} from '../reducers/invoices';
import Invoice from '../components/Invoice';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const InvoiceContainer = ({
  countriesList,
  canCreateInvoice,
  data,
  errors,
  match: {
    params: { id },
  },
  location: { state, pathname },
  history,
  invoicePageRequest,
  setInvoiceCancelled,
}) => {
  const [showModal, setShowModal] = useState(state?.showModal);
  const [showCancelModal, setShowCancelModal] = useState();
  const [cancelError, setCancelError] = useState('');
  const onCloseModal = () => {
    setShowModal(false);
    setShowCancelModal(false);
  };
  const goBack = () => history.push(invoicesRoute);
  const isLoading = isEmpty(data) || isEmpty(countriesList);

  if (state?.showModal) {
    window.history.replaceState(
      {
        showModal: false,
      },
      '',
      pathname
    );
  }

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const cancelInvoice = async () => {
    setCancelError('');
    const { error } = await api.cancelInvoice(id);
    if (error) {
      setCancelError(error);
    } else {
      setInvoiceCancelled(id);
    }

    setShowCancelModal(false);
  };

  const duplicateInvoice = () => {
    const newInvoiceData = {
      amount: data.totalAmount.value,
      billingAddress: data.customer.billingAddress,
      city: data.customer.city,
      country: {
        label: getCountryLabel(countriesList.countries, data.customer.country),
        value: data.customer.country,
      },
      currency: data.totalAmount.currency,
      customerEmail: data.customer.email,
      customerName: data.customer.name,
      description: data.description,
      postCode: data.customer.postCode,
      state: data.customer.state,
      sendIssuedEmail: data.sendIssuedEmail ? ['on'] : [],
    };

    history.push({
      pathname: '/payments/invoices/new',
      state: { initialFormState: newInvoiceData },
    });
  };

  useEffect(() => {
    invoicePageRequest(id);
  }, []);

  if (errors) return <NotFound />;

  return (
    <Layout
      heading="Invoice details"
      sideAction={<BackButton onGoBack={goBack} />}
      sideContent={
        <InvoiceActions
          duplicate={duplicateInvoice}
          disabled={!canCreateInvoice}
          status={data.status}
        />
      }
    >
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <InvoiceSentModal
            isOpen={showModal}
            onClose={onCloseModal}
            emailSent={data.sendIssuedEmail}
          />
          <CancelInvoiceConfirmationModal
            isOpen={showCancelModal}
            onClose={onCloseModal}
            onSubmit={cancelInvoice}
          />
          <motion.div initial="hidden" animate="visible" variants={variants}>
            <Invoice
              cancel={openCancelModal}
              countries={countriesList.countries}
              duplicate={duplicateInvoice}
              error={cancelError}
              invoice={data}
              invoiceDuplicateDisabled={!canCreateInvoice}
            />
          </motion.div>
        </>
      )}
    </Layout>
  );
};

InvoiceContainer.propTypes = {
  ...routePropTypes,
  countriesList: PropTypes.shape({}),
  data: PropTypes.shape(orderPropType),
  errors: PropTypes.objectOf(PropTypes.string),
  invoicePageRequest: PropTypes.func.isRequired,
  canCreateInvoice: PropTypes.bool,
};

export default connect(
  (state, props) => ({
    countriesList: getCountriesList(state),
    data: getInvoiceContainer(state, props.match.params.id),
    canCreateInvoice:
      authSelectors.invoiceCreationPermission(state) === managePermission,
    errors: getLoadInvoicesError(state),
  }),
  {
    invoicePageRequest: actions.singleInvoicePageRequest,
    setInvoiceCancelled: actions.singleInvoiceSetCancelled,
  }
)(InvoiceContainer);
