import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { selectors as authSelectors } from 'auth';
import { selectors as featuresSelectors } from 'features';
import { selectors as sessionSelectors } from 'session';
import { route as routePropType } from 'common/propTypes';
import { kybStarted, supportUrl } from 'common/constants';
import {
  apiIntegrationsRoute,
  getStartedRoute,
  integrationsRoute,
  ordersRoute,
  paymentsRoute,
  personalRoute,
  settingsRoute,
  settlementsRoute,
  signOutRoute,
} from 'app/containers/routes';
import { getAuthServiceUrl } from 'auth/utils/getAuthServiceUrl';
import {
  permissions as permissionsProptype,
  managePermission,
} from '../../payments/propTypes';
import Sidebar, { SidebarLink } from '../components/Sidebar';
import { actions as ordersActions } from '../../payments';

class SidebarContainer extends Component {
  canManageCredentials = () => {
    const {
      permissions: { storeCredentials },
    } = this.props;

    return storeCredentials === managePermission;
  };

  canManageSettlements = () => {
    const {
      permissions: { settlements },
      isSettlementsActive,
    } = this.props;

    return settlements === managePermission && isSettlementsActive;
  };

  showGetStartedLink = () => {
    const { merchantComplianceStatus } = this.props;
    return merchantComplianceStatus === kybStarted;
  };

  showAppLinks = () => {
    const { merchantComplianceStatus } = this.props;

    return merchantComplianceStatus !== kybStarted;
  };

  showSettlements = (showAppLinks) =>
    this.canManageSettlements() && showAppLinks;

  showOrganization = (showAppLinks) =>
    this.canManageCredentials() && showAppLinks;

  render() {
    const {
      location: { pathname },
      resetTable,
    } = this.props;

    const showAppLinks = this.showAppLinks();
    const showGetStartedLink = this.showGetStartedLink();
    const showSettlements = this.showSettlements(showAppLinks);
    const showOrganization = this.showOrganization(showAppLinks);
    const { isSSOActive } = this.props;

    return (
      <Sidebar
        activePath={pathname}
        signOutLink={{ href: signOutRoute }}
        supportLink={{ href: supportUrl }}
        resetTable={resetTable}
      >
        {showGetStartedLink && (
          <SidebarLink
            to={getStartedRoute}
            name="Get Started"
            icon={{
              name: 'play',
              description: 'Get Started',
            }}
          />
        )}
        {isSSOActive && (
          <SidebarLink
            external
            to={getAuthServiceUrl()}
            name="Verification"
            icon={{
              name: 'shield_check',
              description: 'Verification',
            }}
          />
        )}
        {showAppLinks && (
          <SidebarLink
            to={ordersRoute}
            pathPattern={`${paymentsRoute}/*`}
            name="Payments"
            icon={{
              name: 'payments',
              description: 'Payments Area',
            }}
            onClick={resetTable}
          />
        )}
        {showSettlements && (
          <SidebarLink
            pathPattern={`^${settlementsRoute}/*`}
            to={settlementsRoute}
            name="Settlements"
            icon={{
              name: 'settlements',
              description: 'Settlements Area',
            }}
          />
        )}
        {showOrganization && (
          <SidebarLink
            to={apiIntegrationsRoute}
            name="Integrations"
            pathPattern={`${integrationsRoute}/*`}
            icon={{
              name: 'plug',
              description: 'Integrations Area',
            }}
          />
        )}
        {showAppLinks && (
          <SidebarLink
            to={personalRoute}
            name="Settings"
            pathPattern={`${settingsRoute}/*`}
            icon={{
              name: 'cog',
              description: 'Account Area',
            }}
          />
        )}
      </Sidebar>
    );
  }
}

SidebarContainer.propTypes = {
  ...routePropType,
  resetTable: PropTypes.func.isRequired,
  permissions: permissionsProptype.isRequired,
  isSSOActive: PropTypes.bool.isRequired,
  isSettlementsActive: PropTypes.bool.isRequired,
  merchantIntegrationStatus: PropTypes.string,
  merchantComplianceStatus: PropTypes.string,
};

const ConnectedSidebarContainer = connect(
  (state) => ({
    isSSOActive: featuresSelectors.isSSOActive(state),
    permissions: authSelectors.getPermissions(state),
    isSettlementsActive: featuresSelectors.isSettlementsActive(state),
    merchantIntegrationStatus:
      sessionSelectors.activeOrganization(state)?.integrationStatus,
    merchantComplianceStatus:
      sessionSelectors.activeOrganization(state)?.complianceStatus,
  }),
  { resetTable: ordersActions.resetOrdersTable }
)(SidebarContainer);

export default withRouter(ConnectedSidebarContainer);
