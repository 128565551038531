import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const TextArea = ({ error, errorId, id, required, ...textAreaProps }) => {
  const textAreaClasses = classNames(styles.textArea, {
    [styles.error]: error,
  });

  return (
    <textarea
      {...textAreaProps}
      aria-invalid={error}
      aria-describedby={errorId}
      data-error={error}
      id={id}
      required={required}
      className={textAreaClasses}
    />
  );
};

TextArea.propTypes = {
  error: PropTypes.bool,
  errorId: PropTypes.string,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  error: false,
  errorId: '',
  required: false,
};

export default TextArea;
